import Link from 'next/link';

import DropdownWrapper from '../dropdown-wrapper/DropdownWrapper';

import stl from './DropdownRecommend.module.scss';

const recommendations = [
  { title: 'Розы', href: '/catalog/bukety/flower_roza' },
  { title: 'Хризантемы', href: '/catalog/bukety/flower_hrizantema' },
  { title: 'Гербера', href: '/catalog/bukety/flower_gerbera' },
  { title: 'Лизиантус', href: '/catalog/bukety/flower_liziantus' },
];

const DropdownRecommend = ({ onItemClick }: { onItemClick: () => void }) => {
  return (
    <DropdownWrapper title="Часто ищут" className={stl.appear}>
      {recommendations.map((product) => (
        <li key={product.title} className={stl.item} onClick={onItemClick}>
          <Link href={product.href}>{product.title}</Link>
        </li>
      ))}
    </DropdownWrapper>
  );
};

export default DropdownRecommend;

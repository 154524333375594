import { useRouter } from 'next/router';
import { FC, HTMLAttributes } from 'react';

import { Image } from '@/components/image';

import { HighlightWords } from '@/helpers/highlight-words';
import { formatCurrency } from '@/helpers/number/format-currency';

import { IProductNew } from '@/types/new/products';

import DropdownWrapper from '../dropdown-wrapper/DropdownWrapper';

import styles from './Dropdown.module.scss';

interface SearchDropdownProps extends HTMLAttributes<HTMLDivElement> {
  suggestions: IProductNew[];
  searchTerm: string;
  onItemClick: () => void;
}

const SearchDropdown: FC<SearchDropdownProps> = ({
  suggestions,
  searchTerm,
  onItemClick,
  ...rest
}) => {
  const router = useRouter();

  const onClickHandler = async (slug: string) => {
    onItemClick();
    await router.push(`/product/${slug}`);
  };

  return (
    <DropdownWrapper title="Результаты поиска" {...rest}>
      {suggestions.map((product) => {
        return (
          <li key={product.id} className={styles.item} onClick={() => onClickHandler(product.slug)}>
            <Image
              className={styles.item__image}
              src={product.media[0].url}
              width={60}
              height={60}
              priority
              alt={product.name}
            />
            <div className={styles.item__name}>
              <HighlightWords text={product.name} words={searchTerm} color="#EA0075" />
            </div>
            <div className={styles.item__price}>
              <p>{formatCurrency(product.price)}</p>
              {Boolean(product.sale) && (
                <span>{formatCurrency(Math.ceil(product.price / (1 - product.sale)))}</span>
              )}
            </div>
          </li>
        );
      })}
    </DropdownWrapper>
  );
};

export default SearchDropdown;

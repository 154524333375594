import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import { Container } from '@/components/container';
import { SliderButtonLeft } from '@/components/ui/buttons/slider-buttons/SliderButtonLeft';
import { SliderButtonRight } from '@/components/ui/buttons/slider-buttons/SliderButtonRight';
import { Skeleton } from '@/components/ui/loaders/Skeletons/Skeletons';

import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useVisibleItems } from '@/hooks/useVisibleItems';

import { useGetInstagramReviewsQuery } from '@/store/api/reviews.api';

import { ISwiper } from '@/types/ISwiper';

import styles from './Reviews.module.scss';
import { ReviewItem } from './review-item/ReviewItem';

const breakPoints = {
  0: {
    slidesPerView: 2.1,
    centeredSlides: true,
    spaceBetween: 6,
  },
  400: {
    slidesPerView: 2.25,
    spaceBetween: 6,
    centeredSlides: true,
  },
  480: {
    slidesPerView: 1.5,
    spaceBetween: 16,
    centeredSlides: false,
  },
  550: {
    slidesPerView: 2.2,
    spaceBetween: 16,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 16,
  },
  998: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
};

const Reviews = ({
  loop = false,
  limit,
  ids,
  titleComponent = 'h2',
}: {
  loop?: boolean;
  limit?: number;
  ids?: number[];
  titleComponent?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}) => {
  const { data, isLoading } = useGetInstagramReviewsQuery({ ids, limit });

  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const isMobileDevice = useMediaQuery('768px');
  const { visibleItems, containerRef } = useVisibleItems(
    !isLoading ? data : [],
    isMobileDevice ? 2 : 4
  );
  const handleTapNext = () => swiper?.slideNext();
  const handleTapPrev = () => swiper?.slidePrev();

  const Title = titleComponent;

  return (
    <>
      <Container>
        <div className={styles.header}>
          <Title className={styles.h2}>Отзывы</Title>

          <div className={styles.header__arrows}>
            <SliderButtonLeft onClick={handleTapPrev} />
            <SliderButtonRight onClick={handleTapNext} />
          </div>
        </div>
      </Container>
      <Container className={styles.noPadding}>
        <>
          <Swiper
            ref={containerRef}
            initialSlide={1}
            slidesPerView={4}
            spaceBetween={16}
            breakpoints={breakPoints}
            className={styles.swiper}
            loop={loop}
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          >
            {isLoading
              ? [...new Array(4)].map((_, i) => (
                  <SwiperSlide key={i}>
                    <Skeleton className={styles.skeleton} />
                  </SwiperSlide>
                ))
              : visibleItems?.map((review: any) => {
                  const imageUrl = getImageFormatUrl(review.image?.[0], 'medium');
                  const avatarUrl = getImageFormatUrl(review.avatar?.[0], 'small');

                  return (
                    <SwiperSlide key={review.id}>
                      <ReviewItem
                        nickname={review.nickname}
                        image={imageUrl}
                        avatar={avatarUrl}
                        text={review.text}
                      />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </>
      </Container>
    </>
  );
};
export default Reviews;

import clsx from 'clsx';
import { FC, memo, useCallback } from 'react';

import { Image } from '@/components/image';

import { useHasMounted } from '@/hooks/useHasMounted';

import styles from './ReviewItem.module.scss';

interface ReviewItemProps {
  nickname: string;
  avatar?: string;
  image?: string;
  text?: string | null;
}

const defaultColor = `hsl(72.65076017335586, 38.821609240134016%, 85.31492118335075%)`;

export const ReviewItem: FC<ReviewItemProps> = ({ nickname, avatar, image, text }) => {
  const hasMounted = useHasMounted();
  const getColor = useCallback(
    () =>
      'hsl(' +
      360 * Math.random() +
      ',' +
      (25 + 70 * Math.random()) +
      '%,' +
      (85 + 10 * Math.random()) +
      '%)',
    []
  );

  return (
    <div
      className={clsx(styles.wrapper, text && styles.wrapperWithText)}
      style={{ backgroundColor: hasMounted ? getColor() : defaultColor }}
    >
      {image && (
        <Image src={image} fill className={styles.image} loading="lazy" alt={`Отзыв ${nickname}`} />
      )}
      {text && (
        <div className={styles.textWrapper}>
          <div className={styles.text}>{text}</div>
        </div>
      )}

      <div className={styles.body}>
        <div className={styles.body__avatar}>
          {avatar && (
            <Image alt={`Отзыв ${nickname}`} src={avatar} loading="lazy" fill sizes="96px" />
          )}
        </div>
        <div className={styles.body__instagram}>
          <div className={styles.body__instagram_icon}>
            <svg>
              <use href={`/static/icons/sprite-instagram.svg#instagram`} />
            </svg>
          </div>
          <span className={styles.body__instagram_nickname}>@{nickname}</span>
        </div>
      </div>
    </div>
  );
};
